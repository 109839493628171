const npsLocalizations = {
  cs: {
    title: 'Pomůžete společnosti Egencia poskytnutím názoru?',
    button: 'Poskytněte názor'
  },
  da: {
    title: 'Ønsker du at give feedback og dermed hjælpe Egencia?',
    button: 'Giv feedback'
  },
  de: {
    title: 'Helfen Sie Egencia mit Ihrem Feedback?',
    button: 'Feedback geben'
  },
  en: {
    title: 'Will you help Egencia by providing feedback?',
    button: 'Give Feedback'
  },
  es: {
    title: 'Su opinión nos ayuda a mejorar el servicio que le ofrecemos.',
    button: 'Enviar opinión'
  },
  fi: {
    title: 'Auttaisitko Egenciaa antamalla palautetta?',
    button: 'Anna palautetta'
  },
  fr: {
    title: 'Voulez-vous aider Egencia en laissant un commentaire?',
    button: 'Laisser un commentaire'
  },
  'fr-ca': {
    title: 'Voulez-vous aider Egencia en fournissant des commentaires?',
    button: 'Donner des commentaires'
  },
  it: {
    title: 'Vuoi aiutarci a migliorare i servizi di Egencia?',
    button: 'Invia un feedback'
  },
  nb: {
    title: 'Kan du hjelpe Egencia ved å gi oss din tilbakemelding?',
    button: 'Gi tilbakemelding'
  },
  nl: {
    title: 'Kunt u Egencia helpen door feedback te geven?',
    button: 'Feedback geven'
  },
  pl: {
    title: 'Czy podzielą się Państwo z nami swoją opinią o portalu Egencia?',
    button: 'Prześlij opinię'
  },
  pt: {
    title: 'Os seus comentários ajudam a Egencia a melhorar!',
    button: 'Enviar comentários'
  },
  sv: {
    title: 'Kan du hjälpa Egencia genom att ge feedback?',
    button: 'Ge feedback'
  },
  tr: {
    title: "Görüş bildirerek Egencia'ya yardımcı olmak ister misiniz?",
    button: 'Görüş Bildirin'
  },
  zh: {
    title: '您愿意为易信达提供反馈吗？',
    button: '提供反馈'
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const stateKey = "uitk.nps.state";
  const lastActionKey = "uitk.nps.lastAction";

  if(uitk.adapt && uitk.adapt.webView) return; //if in a native app webview do not show
  if((EG.npsSurveyOptions && !EG.npsSurveyOptions.isEnabled)) return; //if config disabled do not show
  if(!EG.user || EG.user.user_id === -1) return; //if the user is not logged don't show or update (cross session pollution is possible but modifying state resets the counter on log-out which is a bigger issue)
  if(EG.user && EG.user.roleType === "Agent") return; //bandaid to reject agents on naughty apps that don't update
  if(EG.appName === "feedback") return; //showing on the feedback app is a bit much

  const pageCountTrigger = EG.npsSurveyOptions ? EG.npsSurveyOptions.pageCountTrigger : 3;
  const percentToGetSurvey = 100;
  const shownAfterSeconds = EG.npsSurveyOptions ? EG.npsSurveyOptions.shownAfterSeconds : 10;
  const daysUntilRevive = EG.npsSurveyOptions ? EG.npsSurveyOptions.daysUntilRevive : 30;
  const state = initState(localStorage.getItem(stateKey));
  let notificationTriggered = false;

  switch (state) {
    case -1: //We should check if we need to revive after not-chosen or finished X days ago.
      const lastActionDate = new Date(localStorage.getItem(lastActionKey)); //if the storage was empty it will be interpreted as 0 = 1/1/1970, which should be well over the threshold
      if (!isNaN(lastActionDate)) {
        if ((lastActionDate.getTime() + (1000 * 60 * 60 * 24 * daysUntilRevive)) < (new Date()).getTime()) {
          initState(); //force re-init with undefined value, same as if storage was empty
        }
      } else {
        //shouldn't happen but if bad data got in we'll clear it and start over
        localStorage.removeItem(lastActionKey); 
      }
      return;
    case 0:
      document.body.addEventListener("mouseleave", function moveEvent(e) {
        if (e.clientY < 100) {
          setupSurveyNotification();
          document.body.removeEventListener("mouseleave", moveEvent);
        }
      });
      setTimeout(() => setupSurveyNotification(), 1000 * shownAfterSeconds);
      return;
    default:
      localStorage.setItem(stateKey, state - 1);
      return;
  }

  function initState(state) {
    state = parseInt(state);
    if (isNaN(state)) {
      if ((EG.npsSurveyOptions !== undefined && EG.npsSurveyOptions != null && EG.npsSurveyOptions.isEnabled)
          || Math.random() <= (percentToGetSurvey / 100)) { //Chosen for survey
        state = pageCountTrigger;
        localStorage.setItem(stateKey, pageCountTrigger);
      } else { //Not Chosen, same as if you had just completed a survey
        state = -1;
        localStorage.setItem(stateKey, -1);
        localStorage.setItem(lastActionKey, (new Date()).toISOString());
      }
    }
    return state;
  }

  function remove(element) {
    element.parentNode.removeChild(element);
  }

  function setupSurveyNotification() {
    if (notificationTriggered) return;
    if(document.body.querySelector(".modal-background.active")){ 
      setTimeout(() => setupSurveyNotification(), 5000);
      return; //don't open over a modal, try again later
    }
    notificationTriggered = true;
    let localizations = resolveLocaleProperties(EG.currentLocale);
    const surveyAlert = document.createElement("div");
    surveyAlert.setAttribute("class", "init");
    surveyAlert.id = "nps-survey";
    surveyAlert.innerHTML = `
                <h1>${localizations.title}</h1>
                <button id='nps-survey-proceed' class='btn btn-med'>
                    <span class='btn-label'>${localizations.button}</span>
                </button>
                <button id='nps-survey-cancel'><uitk-icon name="close"></uitk-icon></button>
            `;
    surveyAlert.querySelector("#nps-survey-proceed").addEventListener("click", () => {
      const feedbackNpsContent = `<iframe class="feedback-content" src=${uitk.getFeedbackSurveyUrl({context: "prompt"})} title="Feedback Panel"></iframe>`
      const feedbackNpsWidget = uitk.modal.create({
        modalId: 'feedback-nps-widget',
        content: feedbackNpsContent,
        title: ' ',
        panel: true,
        panelSize: "medium"
      })
      feedbackNpsWidget.open();
      remove(surveyAlert);
      uitk.analytics.track('a', {
        linkId: "site-intercept-survey.proceed",
        linkName: "site-intercept-survey.proceed",
        linkTrackVars: "prop16"
      });
    });
    surveyAlert.querySelector("#nps-survey-cancel").addEventListener("click", () => {
      remove(surveyAlert);
      uitk.analytics.track('a', {
        linkId: "site-intercept-survey.cancel.explicit",
        linkName: "site-intercept-survey.cancel.explicit",
        linkTrackVars: "prop16"
      });
    });
    const surveyStyles = document.createElement("style");
    surveyStyles.textContent = `
                #nps-survey { position: fixed; bottom: 2rem; right: 2rem; width: 400px; padding: 1.6rem 2rem 1.6rem 2rem; background: #fff; box-shadow: 0 2px 7px rgba(0,0,0,.15); transition: transform 1s; z-index: 999; }
                #nps-survey.init { transform: translateY(calc(100% + 2rem)); }
                #nps-survey-cancel { top: 0.8rem; right: 0.8rem; position: absolute; margin: 0; font-size: 1.6rem; border: none; color: rgb(104, 119, 129); background: none; }
                #nps-survey-proceed { background-color: #006fcf; color: #fff; width: 100%; }

                @media (max-width: 540px){
                    #nps-survey { width: auto; right: 0; left: 0; bottom: 0; }
                }
            `;
    document.body.appendChild(surveyStyles);
    document.body.appendChild(surveyAlert);
    setTimeout(() => surveyAlert.setAttribute("class", ""), 0); //needed to trigger animation
    uitk.analytics.set("prop18", "site-intercept-survey.displayed").track('o', {
      linkName: "site-intecept-survey",
      linkTrackVars: "prop18"
    });

    //do not show after shown the first time
    localStorage.setItem(stateKey, -1);
    localStorage.setItem(lastActionKey, (new Date()).toISOString());
  }

  function resolveLocaleProperties(locale) {
    const normalLocale = locale.toLowerCase();
    if (npsLocalizations[normalLocale]) {
      return npsLocalizations[normalLocale];
    }
    const language = normalLocale.split("-")[0];
    if (npsLocalizations[language]) {
      return npsLocalizations[language];
    }
    return npsLocalizations["en"];
  }
});
